import http from "../utils/http";
import { stringify } from 'qs';

export function getList(params) {
    return http.get("/insurance/insuranceList?" + stringify(params));
}

export function getExport(params) {
    return http.get("/insurance/update?" + stringify(params));
}

export function getExportIn(formData) {
    const options = {
        headers: {
                  'Content-Type': 'multipart/form-data'
                }
    };
    return http.post("/upload/insuranceExcel", formData, options);
}
