<template>
  <div class="overall">
    <div class="content">
      <!-- 检索条件 -->
      <el-form ref="form" :model="form"> 
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form label-width="90px">
            <el-form-item label="骑手名字">
                <el-input placeholder="请输入骑手名字" v-model.trim="form.name"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
          <el-form label-width="90px">
            <el-form-item label="门店名">
              <el-select v-model="form.siteName" placeholder="请选择门店" style="width: 100%" :clearable="true">
                  <el-option
                    v-for="item in siteIdList"
                    :key="item.value"
                    :label="item.label" 
                    :value="item.label">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
          <el-form label-width="90px">
            <el-form-item label="手机号">
                <el-input placeholder="请输入手机号" v-model.trim="form.phone"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
          <el-form label-width="90px">
            <el-form-item label="身份证号">
                <el-input placeholder="请输入身份证号" v-model.trim="form.idcard"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
   
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form label-width="90px">
            <el-form-item label="地址">
                <el-input placeholder="请输入地址" v-model.trim="form.address"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6">
          <el-form label-width="90px">
            <el-form-item label="上保险时间">
              <el-date-picker
                v-model.trim="form.keyTime"
                type="date"
                value-format="yyyy-MM-dd"
                style="width: 100%;"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="6" style="margin-left: 90px;">
            <el-button type="primary" @click="seach()">查询</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
        </el-col>
      </el-row>
    </el-form>
      <div style="padding-top: 10px">
        <el-button type="primary" @click="downloadExcel()">下载导入模板</el-button>
        <el-button type="primary" @click="handleExportIn()">导入保险表</el-button>
        <!-- <el-button type="primary" @click="handleExportOut()">导出</el-button> -->
      </div>

      <!-- table -->
      <div class="tableBox">
            <el-table
                :data="tableData"
                v-loading="tableLoading"
                max-height="650"
                border
                ref="multipleTable"
                style="width: 100%">
                <!-- <el-table-column
                type="selection"
                width="55">
                </el-table-column> -->
                <el-table-column
                    prop="createAt" 
                    label="创建时间"
                    width="200">
                </el-table-column>
                <el-table-column
                    prop="name" 
                    label="骑手">
                </el-table-column>
                <el-table-column
                    prop="phone" 
                    label="手机号">
                </el-table-column>
                <el-table-column
                    prop="idcard" 
                    label="身份证号">
                    <template slot-scope="scope">{{ scope.row.idcard? scope.row.idcard.replace(/^(.{6})(?:\w+)(.{4})$/,"\$1********\$2"):""}}</template>
                </el-table-column>
                <el-table-column
                    prop="siteName" 
                    label="门店">
                </el-table-column>
                <el-table-column
                    prop="address" 
                    label="地址">
                </el-table-column>
                <el-table-column
                    prop="url" 
                    label="保险">
                </el-table-column>
                <el-table-column
                    prop="number" 
                    label="保单号">
                </el-table-column>
                <el-table-column
                    prop="startTime" 
                    label="保单生效时间">
                </el-table-column>
                <el-table-column
                    prop="endTime" 
                    label="保单结束时间">
                </el-table-column>
                <el-table-column
                    prop="createName" 
                    label="操作人">
                </el-table-column>
            </el-table>
        </div>
<!-- 分页 -->
        <div class="paginationBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            background
            :total="total">
          </el-pagination>
        </div>
    </div>
    <el-dialog
      title="导入保险表"
      :visible.sync="updateVisible"
      :before-close="beforeClose"
      width="30%">
        <el-upload action="" :http-request="importFile" :show-file-list="false" class="inline-block">
          <el-button icon="el-icon-upload2" size="small" type="primary" :loading="uploadLoading">点击上传</el-button>
          <div slot="tip" class="el-upload__tip" style="margin-top: 20px;">请上传 .xls,.xlsx 标准格式文件</div>
        </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="beforeClose()">返回</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {getList,getExport,getExportIn} from '../../../api/safetyProtocol';
import store from '../../../store';
import {siteInfoList,} from '../../../api/restaurant';
export default {
  data(){
    return{
        ids:[],
        siteIdList:[],
        currentPage:1,
        pageSize:10,
        total:0,
        tableData: [],
        tableLoading: true,
        form:{
        name:"",
        siteName:"",
        phone:"",
        address:"",
        idcard:"",
        keyTime:"",
        },
        name:"",
        siteName:"",
        phone:"",
        idcard:"",
        keyTime:"",
        address:"",
        updateVisible:false,
        uploadLoading: false,
    }
  },

  mounted(){
      this.safetyProtocolList();
      this.siteInfoList();
  },

  methods:{
    // 列表
    async safetyProtocolList(){
      try {
        const params = {
         page:this.currentPage,
         limit:this.pageSize,
        }
        let values = {
          ...params,
          ...this.form
        };
       this.tableLoading = true;
       let data =  await getList(values);
       this.total = data.total;
       this.tableData = data.insuranceList;
      } catch (error) {
        console.log("error",error)
      }
      this.tableLoading = false;
    },
    //搜索
    seach() {
      this.safetyProtocolList();
    },
    // 重置
    reset() {
      this.form.name = "";
      this.form.siteName="",
      this.form.phone="",
      this.form.idcard="",
      this.form.keyTime="",
      this.form.address="",
      this.currentPage = 1;
      this.safetyProtocolList();
    },
    // 导出数据
    handleExportOut() {
           this.$confirm("确认导出数据?", {
               confirmButtonText: "确定",
               cancelButtonText: "取消",
               type: "warning"
           }).then(() => {
               const params = {
                page:this.currentPage,
                limit:this.pageSize,
                ids:this.ids
               };
               return getExport(params);
           }).then(() => {
               this.clockinList();
               this.$message({
                    showClose: true,
                    type: "success",
                    message: "操作成功!"
               });
            }).catch(() => {
              this.$message({
              type: 'info',
              message: '已取消导出'
              });          
            });
       },
       //导入表格
       handleExportIn(){
        this.updateVisible = true
       },
       //弹窗取消
      beforeClose() {
        this.updateVisible = false;
      },

    //复选框
    handleSelectionChange(selectionList) {
            this.ids = [];
            selectionList.forEach(ele => {
            this.ids.push(ele.id);
        });
        return this.ids.join(",");
      },

      //更改一页的条数
      handleSizeChange(size) {
            this.pageSize = size;
            //重新走页面初始化方法
            this.safetyProtocolList();
        },
        //更改页数
        handleCurrentChange(current) {
            this.currentPage = current;
            //重新走页面初始化方法
            this.safetyProtocolList();
        },
        // 门店下拉
    async siteInfoList() {
      try {
        let data = await siteInfoList();
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.id,
            label: item.name
          })
        })
        this.siteIdList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },
      //导入excel
      async importFile(file) {
        try {
              let index = file.file.name.lastIndexOf(".");
              let fileName = file.file.name.substring(index + 1, file.file.name.length);
              if (fileName == "xls" || fileName == "xlsx") {
                this.uploadLoading = true;
                  let formData = new FormData()
                  formData.append('file', file.file)
                  formData.append('userId', store.state.login.userinfo.id)
                  await getExportIn(formData);
                  this.$message({
                      showClose: true,
                      type: "success",
                      message: "文件导入成功!"
                });
                  this.safetyProtocolList();
              } else {
                this.$message.error("请导入 .xls,.xlsx 标准格式文件")
              }
              this.updateVisible = false;
          } catch (error) {
            console.log("error",error)
          }
          this.uploadLoading = false;
     },
     downloadExcel() {
        let a = document.createElement("a");
        a.href = "https://qibangerp.oss-cn-beijing.aliyuncs.com/erp/instruct/%E5%BD%95%E5%85%A5%E4%BA%BA%E5%91%98%E4%BF%9D%E9%99%A9%E4%BF%A1%E6%81%AF.xlsx";
        //a.download = "保险文件模板.xlsx";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
  }

}
</script>

<style lang="scss" scoped>
.overall {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}

</style>